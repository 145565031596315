import { LinguiJSMessageDescriptor } from '@lib/types/common'
import { defineMessage } from '@lingui/macro'

type CourseDataType = {
  title: LinguiJSMessageDescriptor
  link: string
}

export const COURSES_DATA: CourseDataType[] = [
  {
    title: defineMessage({ message: `Scratch Programming` }),
    link: '/courses/scratch-programming/',
  },
  {
    title: defineMessage({ message: `Python for Kids` }),
    link: '/courses/python-for-kids/',
  },
  {
    title: defineMessage({ message: `Game Development for Kids` }),
    link: '/courses/game-development/',
  },
  {
    title: defineMessage({ message: `Website Development` }),
    link: '/courses/web-development/',
  },
  {
    title: defineMessage({ message: `App Development for Kids` }),
    link: '/courses/app-development/',
  },
  {
    title: defineMessage({ message: `Data Science for Teens` }),
    link: '/courses/data-science/',
  },
  {
    title: defineMessage({ message: `Roblox` }),
    link: '/courses/roblox-coding/',
  },
  {
    title: defineMessage({ message: `AP Computer Science A` }),
    link: '/courses/ap-computer-science-a/',
  },
  {
    title: defineMessage({ message: `IOI Algorithms Course` }),
    link: '/courses/ioi-algorithms-course/',
  },
  {
    title: defineMessage({ message: `Math` }),
    link: '/courses/math/',
  },
]

export const CAMPUS_LINKS: CourseDataType[] = [
  {
    title: defineMessage({ message: `Summer Coding Camp` }),
    link: '/kids-teens-coding-camps/summer/',
  },
  {
    title: defineMessage({ message: `Winter Coding Camp` }),
    link: '/kids-teens-coding-camps/winter/',
  },
]
